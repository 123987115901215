import React from "react";
import { useParams, useRouteLoaderData } from "react-router-dom";
import MobStatElement from "./MobStatElement";

function MobStatList() {

  const params = useParams();
  const data =  useRouteLoaderData(params.uuid ? "userStats" : "globalStats");

  return (
    <>
      <ul className="mob-stat-list">
        {Object.entries(data.mobs).map(([key, value]) => (
          <MobStatElement key={key} name={key} value={value}/>
        ))}
      </ul>
    </>
  );
}

export default MobStatList;