import React from "react";
import { NavLink } from "react-router-dom";

function NavButton(props) {

  return (
    <>
      <NavLink className={"btn"} to={props.to} disabled={props.disabled}><button disabled={props.disabled}>{props.text}</button></NavLink>
    </>
  );
}

export default NavButton;