import React, { useState } from "react";
import { useParams, useRouteLoaderData } from "react-router-dom";
import ItemStatRow from "./ItemStatRow";

function ItemStatTable() {

  const params = useParams();
  const data =  useRouteLoaderData(params.uuid ? "userStats" : "globalStats");

  const [sortBy, setSortBy] = useState({column: '0', ascending: false});

  function sortFn(a, b) {
    return (b[sortBy.column] - a[sortBy.column]) * (sortBy.ascending ? -1 : 1);
  }

  function handleResort(e) {
    const column = e.target.value;
    const ascending = (sortBy.column === column) ? !sortBy.ascending : false;

    setSortBy({column: column, ascending: ascending});
  }

  return (
    <>
      <table className="item-stat-table">
        <thead>
          <tr>
            <th></th>
            <th><button value={0} onClick={handleResort}>mine</button></th>
            <th><button value={1} onClick={handleResort}>broke</button></th>
            <th><button value={2} onClick={handleResort}>craft</button></th>
            <th><button value={3} onClick={handleResort}>use</button></th>
            <th><button value={4} onClick={handleResort}>pick</button></th>
            <th><button value={5} onClick={handleResort}>drop</button></th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(data.items).sort(([,a],[,b]) => sortFn(a,b)).map(([key, value]) => (
            <ItemStatRow key={key} name={key} value={value}/>
          ))}
        </tbody>
      </table>
    </>
  );
}

export default ItemStatTable;