import React from "react";
import { useParams, useRouteLoaderData } from "react-router-dom";
import { CustomStatsKeys } from "../util/Keys";
import CustomStatElement from "./CustomStatElement";

function CustomStatList() {

  const params = useParams();
  const data =  useRouteLoaderData(params.uuid ? "userStats" : "globalStats");

  return (
    <>
      <ul className="custom-stat-list">
        {CustomStatsKeys.map((key) => (
          <CustomStatElement key={key} name={key} value={data.custom[key] || 0}/>
        ))}
      </ul>
    </>
  );
}

export default CustomStatList;