import React from "react";
import { useTranslation } from "react-i18next";

function ItemStatRow(props) {

  const { t } = useTranslation('game');

  return (
    <>
    <tr className="item-stat">
      <td>{t(['blocks.' + props.name, 'items.' + props.name])}</td>
      <td>{props.value[0]}</td>
      <td>{props.value[1]}</td>
      <td>{props.value[2]}</td>
      <td>{props.value[3]}</td>
      <td>{props.value[4]}</td>
      <td>{props.value[5]}</td>
    </tr>
  </>
  );
}

export default ItemStatRow;