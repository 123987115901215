import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

function MobStatElement(props) {

  const params = useParams();

  const { t } = useTranslation('game');

  return (
    <>
      <li className="mob-stat">
        <p className="mob-stat-name">{t('entities.' + props.name)}</p>
        <p className="mob-stat-value">{t(["stats.killed" + (params.uuid ? "" : "_plural"), "stats.killed"], {count: props.value[0], entity: t('entities.' + props.name)})}</p>
        <p className="mob-stat-value">{t(["stats.killed_by" + (params.uuid ? "" : "_plural"), "stats.killed_by"], {count: props.value[1], entity: t('entities.' + props.name)})}</p>
      </li>
    </>
  );
}

export default MobStatElement;