import React from "react";

import { MapKeys } from "../util/Keys";

import { useTranslation } from "react-i18next";
import { Outlet, useLoaderData, useParams } from "react-router-dom";
import NavButton from "./NavButton";

function StatsContainer(props) {

  const data = useLoaderData();
  const params = useParams();

  const { t } = useTranslation('app');

  return (
    <div>
      {params.uuid && <img className="user-icon" src={"https://mc-heads.net/avatar/" + params.uuid + "/8"} alt={params.uuid}/>}
      <div className="btn-group">
        {MapKeys.map((value) => (
          <NavButton disabled={data[value] == null} key={value} to={value + (params.category ? ("/" + params.category) : "")} text={t("maps." + value)} />
        ))}
      </div>
      <Outlet />
    </div>
  );
}

export function StatsNotFound(props) {

  const params = useParams();

  const { t } = useTranslation('app');

  console.log(params);

  return (
    <>
      <p className="errorMsg" >{t("error.other")}</p>
    </>
  )
}

export default StatsContainer;