import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, Outlet, useNavigate } from "react-router-dom";

function UserSearch(props) {

  let navigate = useNavigate();

  const [searchText, setSearchText] = useState('');
  const { t } = useTranslation('app');

  function handleChange(e) {
    setSearchText(e.target.value);
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (searchText.length > 0) navigate((props.index ? "details/" : "") + searchText);
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <input type="text" value={searchText} onChange={handleChange} autoFocus></input>
        <button type="submit" className="btn" value="user">{t("buttons.search")}</button>
        <NavLink to={(props.index ? "details/" : "") + "global"} ><button type="button">{t("maps.total")}</button></NavLink>
      </form>
      <Outlet />
    </>
  );
}

export default UserSearch;