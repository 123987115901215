import Axios from "axios";

function resolveUUID(str) {
  return getFromMCHeads(str);
}

// eslint-disable-next-line no-unused-vars
function getFromMojang(str) {
  Axios.get("https://api.mojang.com/users/profiles/minecraft/" + str).then((res) => {
    return {uuid: fixUUID(res.data.uuid), name: res.data.name};
  }).catch((e) => {
    return {code: e.response.status};
  });
}

// eslint-disable-next-line no-unused-vars
function getFromAshcon(str) {
  return Axios.get("https://api.ashcon.app/mojang/v2/user/" + str).then((res) => {
    return {uuid: res.data.uuid, name: res.data.username};
  }).catch((e) => {
    return {code: e.response.status};
  });
}

// eslint-disable-next-line no-unused-vars
function getFromMCHeads(str) {
  return Axios.get("https://mc-heads.net/minecraft/profile/" + str).then((res) => {
    return {uuid: fixUUID(res.data.id), name: res.data.name};
  }).catch((e) => {
    if (e.response.status === 0) return getFromAshcon(str);
    return {code: e.response.status};
  });
}

function fixUUID(id) {
  return id.substring(0, 8) + "-" + id.substring(8, 12) + "-" + id.substring(12, 16) + "-" + id.substring(16, 20) + "-" + id.substring(20);
}

export default resolveUUID;