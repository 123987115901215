import Axios from "axios";
import { MapKeys } from "./Keys";
import resolveUUID from "./UUIDResolver";

class DataManager {

  static UUIDs = new Map();

  static userStats = new Map();
  static globalStats = null;

  static async getUUID(str) {
    if (DataManager.UUIDs.has(str)) return {"uuid": DataManager.UUIDs.get(str)};

    const result = await resolveUUID(str);

    if (result.code === 400) throw new Response("Bad request", { status: 400 })
    if (result.code === 404) throw new Response("Not found", { status: 404 })

    // TODO: cachear bien para las 3 opciones: nombre, uui, uuid sin guiones
    DataManager.UUIDs.set(result.name, result.uuid);

    return result;
  }

  static async loadUserStats(uuid, map) {
    if (DataManager.userStats.has(uuid)) return map ? DataManager.userStats.get(uuid)[map] : DataManager.userStats.get(uuid);

    let stats = {};

    for (const key of MapKeys) {
      await Axios.get("/stats/" + key + "/" + uuid + ".json").then((res) => {
        stats[key] = res.data.stats;
      }).catch(() => {
        stats[key] = null;
      });
    }

    DataManager.userStats.set(uuid, stats);
    return map ? stats[map] : stats;
  }

  static async loadGlobalStats(map) {
    if (DataManager.globalStats) return map ?  DataManager.globalStats[map] : DataManager.globalStats;

    let stats = {};

    for (const key of MapKeys) {
      await Axios.get("/stats/global/" + key + ".json").then((res) => {
        stats[key] = res.data;
      }).catch(() => {
        stats[key] = null;
      });
    }

    return map ? stats[map] : stats;
  }

}

export default DataManager;