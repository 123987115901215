import React from "react";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import NavButton from "./NavButton";

function StatsArea(props) {

  const { t } = useTranslation('app');

  return (
    <>
      <div className="btn-group">
        <NavButton to={"general"} text={t("buttons.stats.general")} />
        <NavButton to={"items"} text={t("buttons.stats.items")} />
        <NavButton to={"mobs"} text={t("buttons.stats.mobs")} />
      </div>
      <Outlet />
    </>
  );
}

export default StatsArea;