import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';


import game_es from "./lang/es_es/game.json"
import app_es from "./lang/es_es/app.json"
import game_en from "./lang/en_us/game.json"
import app_en from "./lang/en_us/app.json"
import { createBrowserRouter, Outlet, redirect, RouterProvider } from 'react-router-dom';
import StatsContainer, { StatsNotFound } from './components/StatsContainer';
import DataManager from './util/DataManager';
import StatsArea from './components/StatsArea';
import CustomStatList from './components/CustomStatList';
import ItemStatTable from './components/ItemStatTable';
import MobStatList from './components/MobStatList';
import UserSearch from './components/UserSearch';

i18next.init({
  interpolation: { escapeValue: false },  // React already does escaping
  lng: 'es-ES',
  fallbackLng: 'es-ES',
  resources: {
    "en-US": {
      game: game_en,
      app: app_en
    },
    "es-ES": {
      game: game_es,
      app: app_es
    }
  }
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        index: true,
        element: <UserSearch index={true} />
      },
      {
        path: "details",
        element: <UserSearch />,
        children: [
          {
            path: ":uuid",
            id: "userDetails",
            element: <StatsContainer />,
            loader: async ({params}) => {
              const result = await DataManager.getUUID(params.uuid);
              if (result.uuid && result.uuid !== params.uuid) return redirect("../" + result.uuid);
              return DataManager.loadUserStats(params.uuid)
            },
            errorElement: <StatsNotFound />,
            children: [
              {
                path: ":map",
                element: <StatsArea />,
                id: "userStats",
                loader: async ({params}) => { return DataManager.loadUserStats(params.uuid, params.map) },
                children: [
                  {
                    path: "general",
                    element: <CustomStatList />
                  },
                  {
                    path: "items",
                    element: <ItemStatTable />
                  },
                  {
                    path: "mobs",
                    element: <MobStatList />
                  }
                ]
              }
            ]
          },
          {
            path: "global",
            id: "globalDetails",
            element: <StatsContainer />,
            loader: () => { return DataManager.loadGlobalStats()},
            children: [
              {
                path: ":map",
                element: <StatsArea />,
                id: "globalStats",
                loader: async ({params}) => { return DataManager.loadGlobalStats(params.map) },
                children: [
                  {
                    path: "general",
                    element: <CustomStatList />
                  },
                  {
                    path: "items",
                    element: <ItemStatTable />
                  },
                  {
                    path: "mobs",
                    element: <MobStatList />
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        path: "recap/:id",
        element: <Outlet />,
        children: [
          {
            path: ":map",
            element: <Outlet />
          },
          {
            path: "global",
            element: <Outlet />
          }
        ]
      },
      {
        path: "about",
        element: <Outlet />
      }
    ]
  }
])


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <RouterProvider router={router}>
        <App />
      </RouterProvider>
    </I18nextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
