import React from "react";
import { useTranslation } from "react-i18next";

function CustomStatElement(props) {

  const { t } = useTranslation('game');

  function format(stat, value) {
    switch(stat) {
      case "play_time":
      case "time_since_death":
      case "time_since_rest":
      case "sneak_time":
        return formatTime(value);
      case "walk_one_cm":
      case "crouch_one_cm":
      case "sprint_one_cm":
      case "walk_on_water_one_cm":
      case "fall_one_cm":
      case "climb_one_cm":
      case "fly_one_cm":
      case "walk_under_water_one_cm":
      case "minecart_one_cm":
      case "boat_one_cm":
      case "pig_one_cm":
      case "horse_one_cm":
      case "aviate_one_cm":
      case "swim_one_cm":
      case "strider_one_cm":
        return formatDistance(value);
      case "damage_dealt":
      case "damage_dealt_absorbed":
      case "damage_dealt_resisted":
      case "damage_taken":
      case "damage_blocked_by_shield":
      case "damage_absorbed":
      case "damage_resisted":
        return formatDamage(value);
      case "leave_game":
      case "jump":
      case "drop":
      case "deaths":
      case "mob_kills":
      case "animals_bred":
      case "player_kills":
      case "fish_caught":
      case "talked_to_villager":
      case "traded_with_villager":
      case "eat_cake_slice":
      case "fill_cauldron":
      case "use_cauldron":
      case "clean_armor":
      case "clean_banner":
      case "clean_shulker_box":
      case "interact_with_brewingstand":
      case "interact_with_beacon":
      case "inspect_dropper":
      case "inspect_hopper":
      case "inspect_dispenser":
      case "play_noteblock":
      case "tune_noteblock":
      case "pot_flower":
      case "trigger_trapped_chest":
      case "open_enderchest":
      case "enchant_item":
      case "play_record":
      case "interact_with_furnace":
      case "interact_with_crafting_table":
      case "open_chest":
      case "sleep_in_bed":
      case "open_shulker_box":
      case "open_barrel":
      case "interact_with_blast_furnace":
      case "interact_with_smoker":
      case "interact_with_lectern":
      case "interact_with_campfire":
      case "interact_with_cartography_table":
      case "interact_with_loom":
      case "interact_with_stonecutter":
      case "bell_ring":
      case "raid_trigger":
      case "raid_win":
      case "interact_with_anvil":
      case "interact_with_smithing_table":
      case "interact_with_grindstone":
      case "target_hit":
        return formatNumber(value);
      default:
        console.log("Missing units for stat: " + stat);
        return value + "?";
    }
  }

  function formatTime(ticks) {
    const seconds = ticks / 20.0;
    const minutes = seconds / 60.0;
    const hours = minutes / 60.0;
    const days = hours / 24.0;
    const years = days / 365.0;

    if (years > 0.5) {
      return formatNumber(years) + " y";
    } else if (days > 0.5) {
      return formatNumber(days) + " d";
    } else if (hours > 0.5) {
      return formatNumber(hours) + " h";
    } else if (minutes > 0.5) {
      return formatNumber(minutes) + " m";
    } else {
      return formatNumber(seconds) + " s";
    }
  }

  function formatDistance(cm) {
    const m = cm / 100.0;
    const km = m / 1000.0;

    if (km > 0.5) {
      return formatNumber(km) + " km";
    } else if (m > 0.5) {
      return formatNumber(m) + " m";
    } else {
      return formatNumber(cm) + " cm";
    }
  }

  function formatDamage(value) {
    return formatNumber(value * 0.1);
  }

  function formatNumber(value) {
    return value.toLocaleString(undefined, {maximumFractionDigits:2})
  }

  return (
    <li className="custom-stat">
      <span className="custom-stat-name">{t('stats.custom.' + props.name)}</span>
      <span className="custom-stat-value">{format(props.name, props.value)}</span>
    </li>
  );
}

export default CustomStatElement;